let content = {
    landing: {
        title: "Trash Quiz",
    },
    paragraph1: {
        title: "Qu’est-ce que le littering ?",
        content:
            "Les déchets sauvages (littering en anglais) sont le résultat de la mauvaise habitude de laisser traîner ou de jeter négligemment les déchets dans les espaces publics. En collaboration avec l’Association pour la Sauvegarde du Léman (ASL), nous avons créé un concept créatif et ludique qui sensibilise à cet enjeu environnemental important, véritable menace pour la qualité de l’eau du Léman et les écosystèmes aquatiques.",
    },
    hero1: {
        title: "Notre solution",
    },
    paragraph2: {
        title: "Chaque spectacle est improvisé, donc unique.",
        content:
            "Le TRASH QUIZ est un jeu “télévisé” inspiré du BURGER QUIZ qui se déroulera en plein air. Une étude minutieuse des déterminants des habitudes liées au littering a été effectuée, dans le but de créer des questions qui informent, sensibilisent et changent ces habitudes de manière efficace. Le TRASH QUIZ c’est donc du sérieux, certes, mais c’est surtout drôle, bruyant, décalé ! On vous attend de pied ferme pour le plein de rire ! On joue pour vous tout l’été au bord du lac.",
    },
    people: {
        deborah: {
            title: "Deborah Chirenti",
            content:
                "Deborah Chirenti joue “Mirabelle Sijmetrompepa” (enfin seulement si on ne se trompe pas). Lorsqu’elle ne joue pas au Trash Quiz, elle arpente les scènes romandes dans des spectacles improvisés originaux, tels que “Le Bouquet” ou encore “Les Tarantinos improvisés” !",
        },
        mathieu: {
            title: "Mathieu Fernandez",
            content:
                "Mathieu Fernandez joue “Julien Dessanges”, un père au foyer qui aime flâner le long du lac à ses heures. Quand il ne joue pas au Trash Quiz, vous aurez sans doute la chance de le voir à nouveau sur les planches de théâtres genevois nouvellement ré-ouverts!",
        },
        lygia: {
            title: "Lygia Pavitt",
            content:
                "Lygia Pavitt joue « Véronique Edavida », une joggueuse sportive et colorée qui aime courir le long du lac après ses heures de travail. Lygia, lorsqu’elle ne joue pas au Trash Quiz, administre les autres projets de la troupe Impro Impact -qu’elle a co-fondée!",
        },
        romain: {
            title: "Romain Jacques",
            content:
                "Romain Jacques joue « Romain Chabot », le présentateur du Trash Quiz. Ces talents de présentation s’exercent dans les nombreux lives de sa chaîne twitch dédiée au gaming. Il se murmure également qu’il ferait actuellement une tournée avec son one-man show “Eblouissant!“, à voir absolument!",
        },
        damian: {
            title: "Damian Veiga Löffel",
            content:
                "Damian Veiga Löffel joue « Grégoire Benamar », un as de la finance genevoise, d’ailleurs, déjà très sensibilisé aux questions environnementales. Damian , lorsqu’il ne joue pas au Trash Quiz, administre les autres projets de la troupe Impro Impact -qu’il a co-fondé!",
        },
    },
}

export default content
